//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-200:_9664,_3004,_8896,_882,_8284,_1264,_344,_8820;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-200')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-200', "_9664,_3004,_8896,_882,_8284,_1264,_344,_8820");
        }
      }catch (ex) {
        console.error(ex);
      }